<template>
  <v-container fluid class="pt-0 pb-0 mt-2 regular-report-print-page">
    <v-navigation-drawer
        right
        app
        width="400"
        v-model="report_state.show"
        style="z-index: 9999"
        color="grey lighten-4"
        class="non-printable"
    >
      <v-card tile flat class="transparent">
        <v-card-text class="pa-0">
          <div style="display: flex; align-items: center" class="py-2 pl-3 pr-1 grey darken-2 white--text">
            <div style="flex: 1; font-weight: 500;">Перелік помилок заповнення</div>
            <div style="flex: 0 0 30px">
              <v-btn icon small @click.stop="report_state.show = false" class=" grey darken-2">
                <v-icon color="secondary">mdi-close</v-icon>
              </v-btn>
            </div>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text class="px-2 pt-2 pb-2">
          <div>
            <div style="display: flex; margin-bottom: 5px; padding: 3px; border-radius: 5px;"
                 class="elevation-3 white"
                 v-for="(item, item_idx) in report_state.errors"
                 :key="`error-${item_idx}`"
            >
              <div class="py-1 px-2">
                <div class="mb-1">
                  <v-chip small color="success" label class="mr-1" v-if="item.table_name" style="height: 20px !important;">
                    {{ item.table_name }}
                  </v-chip>
                  <v-chip small color="grey lighten-3" label class="mr-1" style="height: 20px !important;"
                          v-if="item.row_number">
                    {{ `№ рядка: ${item.row_number}` }}
                  </v-chip>
                </div>
                <div style="color: #2d2d2d; font-weight: 400; font-size: .76rem">
                  {{ item.error_text }}
                </div>
              </div>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-navigation-drawer>
    <v-row class="non-printable">
      <v-app-bar color="grey lighten-4"
                 elevation="1"
                 style="align-items: center; padding-top: 8px"
                 dense class="pt-0"
                 height="62"
      >
        <v-select
            :items="regulatory_report_period_type_select" hide-details
            solo color="grey darken-1"
            v-model="header.period_type"
            label="Тип звіту"
            style="flex: 0 0 180px;"
            @change="onDateChange"
            class="mr-2 mini-solo-field"
            required :rules="[v => !!v || 'Це поле є обов’язковим']"
        />
        <v-text-field v-model="header.year"
                      hide-details solo color="grey darken-1"
                      label="Рік"
                      style="flex: 0 0 110px;"
                      class="mr-2 mini-solo-field"
                      v-integer
                      @change="onDateChange"
                      :disabled="!header.period_type"
                      required :rules="[v => !!v || 'Це поле є обов’язковим']"
        />
        <v-text-field v-model="header.number"
                      hide-details solo color="grey darken-1"
                      label="№ розрах."
                      style="flex: 0 0 100px;"
                      class="mr-2 mini-solo-field"
                      v-integer
                      :disabled="!header.year"
                      required :rules="[v => !!v || 'Це поле є обов’язковим']"
        />
        <v-btn class="mr-2 mini-report-button" @click="save_report">Зберегти</v-btn>
        <v-btn class="mr-2 mini-report-button" @click="generate_report">Заповнити</v-btn>
        <v-menu
            bottom
            :close-on-content-click="true"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="mr-2 mini-report-button"
                   v-bind="attrs"
                   v-on="on"
            >
              XML
            </v-btn>
          </template>

          <v-list dense nav>
            <v-list-item dense @click="export_report_to_xml_tax_inspection">
              <v-list-item-content>
                <v-list-item-title>
                  J0901107 (Податкова)
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item dense @click="export_report_to_xml_statistic">
              <v-list-item-content>
                <v-list-item-title>
                  S0110013 (Статистика)
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-menu
            bottom
            :close-on-content-click="true"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="mr-2 mini-report-button"
                   v-bind="attrs"
                   v-on="on"
            >
              Звіти
            </v-btn>
          </template>

          <v-list dense nav>
            <v-subheader>
              Оберіть необхідний звіт
            </v-subheader>
            <v-divider></v-divider>
            <v-list-item dense style="font-size: .8rem"
                         @click="openReportDialog(
                      'accounting_main_report',
                           'Оборотно-сальдова відомість',
                           )"
            >
              <v-list-item-icon class="mr-4">
                <v-icon color="teal" size="20">
                  mdi-poll
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                Оборотно-сальдова відомість
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-btn class="mr-2 mini-report-button" v-if="!report_is_new" @click="questionForDeleteReport">Вилучити</v-btn>
        <v-spacer/>
        <v-btn
            class="grey lighten-3 mr-2 mini-report-button"
            width="54"
            @click.stop="print"
        >
          <v-icon color="primary">mdi-printer</v-icon>
        </v-btn>
      </v-app-bar>
    </v-row>
    <v-dialog v-model="report_dialog.dialog" fullscreen class="media-print non-printable">
      <PrintReport @closeModal="closeReportDialog"
                   :payload_date_start="this.header.date_start"
                   :payload_date_end="this.header.date_end"
                   :auto_generate="true"
                   :component_name="report_dialog.component_name"/>
    </v-dialog>
    <v-row>
      <v-col cols="12" sm="12" md="12">
        <v-tabs class="custom-tabs-transparent" color="success" @change="onTabsChange">
          <v-tab class="non-printable">
            Звіт
          </v-tab>
          <v-tab class="non-printable">
            Налаштування звіту
          </v-tab>

          <v-tab-item class="pa-0" style="height: calc(100vh - 158px); overflow: auto">
            <table class="regulatory-report-table" style="width: 750px; margin-top: 18px">
              <caption>
                <div style="display: flex;">
                  <div style="flex: 1"></div>
                  <div style="flex: 0 0 250px; text-align: left; font-size: 12px;">
                    Додаток 1 <br>
                    до Національного положення (стандарту) <br>
                    бухгалтерського обліку 25 <br>
                    «Спрощена фінансова звітність» <br>
                    (пункт 4 розділу I) <br>
                  </div>
                </div>
                <div style="font-size: 14px; font-weight: bold; padding-top: 15px; padding-bottom: 15px">
                  Фінансова звітність <br>
                  малого підприємства
                </div>
                <div style="line-height: 22px">
                  <div style="display: flex; font-size: 12px; text-align: left;">
                    <div style="display: flex; flex: 1">
                      <div style="flex: 0 0 64px; padding-top: 4px;"></div>
                      <div style="flex: 1;">
                      </div>
                    </div>
                    <div style="flex: 0 0 294px; display: flex">
                      <div style="flex: 0 0 150px; text-align: center">
                      </div>
                      <div style="flex: 1; min-height: 18px; text-align: center">
                        КОДИ
                      </div>
                    </div>
                  </div>
                  <div style="display: flex; font-size: 12px; text-align: left;">
                    <div style="display: flex; flex: 1">
                      <div style="flex: 0 0 64px; padding-top: 4px;"></div>
                      <div style="flex: 1;">
                      </div>
                    </div>
                    <div style="flex: 0 0 294px; display: flex">
                      <div style="flex: 0 0 150px; text-align: center">
                        Дата (рік, місяць, число)
                      </div>
                      <div style="flex: 1; min-height: 18px; display: flex" class="border-codes-first">
                        <div style="flex: 1; border-right: 1px solid #000000">
                          <input type="text"
                                 v-model="header.fill_year"
                                 :class="{'error-input': !header.fill_year}"
                                 style="margin-bottom: 0 !important;"
                                 class="regulatory-report-header-code-input text-center">
                        </div>
                        <div style="flex: 0 0 40px; border-right: 1px solid #000000">
                          <input type="text"
                                 v-model="header.fill_month"
                                 :class="{'error-input': !header.fill_month}"
                                 style="margin-bottom: 0 !important;"
                                 class="regulatory-report-header-code-input text-center">
                        </div>
                        <div style="flex: 0 0 40px;">
                          <input type="text"
                                 v-model="header.fill_day"
                                 :class="{'error-input': !header.fill_day}"
                                 style="margin-bottom: 0 !important;"
                                 class="regulatory-report-header-code-input text-center">
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style="display: flex; font-size: 12px; text-align: left">
                    <div style="display: flex; flex: 1">
                      <div style="flex: 0 0 84px; padding-top: 4px;;">Підприємство</div>
                      <div style="flex: 1;">
                        <input type="text"
                               v-model="header.payer_name"
                               :class="{'error-input': !header.payer_name}"
                               class="regulatory-report-header-input">
                      </div>
                    </div>
                    <div style="flex: 0 0 264px; display: flex">
                      <div style="flex: 0 0 120px; text-align: center">
                        за ЄДРПОУ
                      </div>
                      <div style="flex: 1; min-height: 18px" class="border-codes-middle">
                        <input type="text"
                               v-model="header.payer_ipn_or_passport"
                               :class="{'error-input': !header.payer_ipn_or_passport}"
                               style="margin-bottom: 0 !important;"
                               class="regulatory-report-header-code-input text-center">
                      </div>
                    </div>
                  </div>
                  <div style="display: flex; font-size: 12px; text-align: left;">
                    <div style="display: flex; flex: 1">
                      <div style="flex: 0 0 64px; padding-top: 4px;">Територія</div>
                      <div style="flex: 1;">
                        <input type="text"
                               v-model="header.payer_city_name"
                               :class="{'error-input': !header.payer_city_name}"
                               class="regulatory-report-header-input">
                      </div>
                    </div>
                    <div style="flex: 0 0 264px; display: flex">
                      <div style="flex: 0 0 120px; text-align: center">
                        за КАТОТТГ<sup>1</sup>
                      </div>
                      <div style="flex: 1; min-height: 18px" class="border-codes-first">
                        <input type="text"
                               v-model="header.payer_city_code"
                               :class="{'error-input': !header.payer_city_code}"
                               style="margin-bottom: 0 !important;"
                               class="regulatory-report-header-code-input text-center">
                      </div>
                    </div>
                  </div>
                  <div style="display: flex; font-size: 12px; text-align: left;">
                    <div style="display: flex; flex: 1">
                      <div style="flex: 0 0 140px; padding-top: 4px; line-height: 16px">
                        Організаційно-правова <br>
                        форма господарювання
                      </div>
                      <div style="flex: 1;">
                        <input type="text"
                               v-model="header.payer_organization_type"
                               :class="{'error-input': !header.payer_organization_type}"
                               style="margin-bottom: 0 !important;"
                               class="regulatory-report-header-input text-center">
                      </div>
                    </div>
                    <div style="flex: 0 0 264px; display: flex">
                      <div style="flex: 0 0 120px; text-align: center">
                        за КОПФГ
                      </div>
                      <div style="flex: 1; min-height: 18px;" class="border-codes-middle">
                        <input type="text"
                               v-model="header.payer_organization_type_code"
                               :class="{'error-input': !header.payer_organization_type_code}"
                               style="margin-bottom: 0 !important;"
                               class="regulatory-report-header-code-input text-center">
                      </div>
                    </div>
                  </div>
                  <div style="display: flex; font-size: 12px; text-align: left;">
                    <div style="display: flex; flex: 1">
                      <div style="flex: 0 0 170px; padding-top: 4px;">
                        Вид економічної діяльності
                      </div>
                      <div style="flex: 1;">
                        <input type="text"
                               v-model="header.payer_kved_name"
                               :class="{'error-input': !header.payer_kved_name}"
                               style="margin-bottom: 0 !important;"
                               class="regulatory-report-header-input text-center">
                      </div>
                    </div>
                    <div style="flex: 0 0 264px; display: flex">
                      <div style="flex: 0 0 120px; text-align: center">
                        за КВЕД
                      </div>
                      <div style="flex: 1; min-height: 18px;" class="border-codes-last">
                        <input type="text"
                               v-model="header.payer_kved_code"
                               :class="{'error-input': !header.payer_kved_code}"
                               style="margin-bottom: 0 !important;"
                               class="regulatory-report-header-code-input text-center">
                      </div>
                    </div>
                  </div>
                  <div style="display: flex; font-size: 12px; text-align: left;">
                    <div style="display: flex; flex: 1">
                      <div style="flex: 0 0 200px; padding-top: 4px;">
                        Середня кількість працівників, осіб
                      </div>
                      <div style="flex: 1;">
                        <input type="text"
                               v-model="header.people_count_main"
                               :class="{'error-input': header.people_count_main === null || header.people_count_main === ''}"
                               style="margin-bottom: 0 !important;"
                               class="regulatory-report-header-input text-center">
                      </div>
                    </div>
                    <div style="flex: 0 0 264px; display: flex">
                    </div>
                  </div>
                  <div style="display: flex; font-size: 12px; text-align: left;">
                    <div style="display: flex; flex: 1">
                      <div style="flex: 0 0 400px; padding-top: 4px;">
                        Одиниця виміру: тис. грн з одним десятковим знаком
                      </div>
                      <div style="flex: 1;">
                      </div>
                    </div>
                    <div style="flex: 0 0 264px; display: flex">
                    </div>
                  </div>
                  <div style="display: flex; font-size: 12px; text-align: left;">
                    <div style="display: flex; flex: 1">
                      <div style="flex: 0 0 110px; padding-top: 4px;">
                        Адреса, телефон
                      </div>
                      <div style="flex: 1;">
                        <input type="text"
                               v-model="header.payer_postal_address"
                               :class="{'error-input': !header.payer_postal_address}"
                               style="margin-bottom: 0 !important;"
                               class="regulatory-report-header-input text-center">
                      </div>
                    </div>
                    <div style="flex: 0 0 146px; display: flex">
                      <div style="flex: 0 0 4px; text-align: center">
                      </div>
                      <div style="flex: 1; min-height: 18px; border-bottom: 1px solid #000000">
                        <input type="text"
                               v-model="header.payer_phone"
                               :class="{'error-input': !header.payer_phone}"
                               style="margin-bottom: 0 !important;"
                               class="regulatory-report-header-code-input text-center">
                      </div>
                    </div>
                  </div>
                </div>
                <div
                     style="font-size: 14px; margin-top: 30px; margin-bottom: 30px; line-height: 20px; font-weight: bold; text-align: center">
                  1. Баланс на {{ balanceDate }}
                </div>
                <div class="d-flex" style="text-align: center">
                  <div style="flex: 1"></div>
                  <div style="flex: 0 0 72px">
                    Форма № 1-м
                  </div>
                  <div style="flex: 0 0 90px">
                    Код за ДКУД
                  </div>
                  <div style="flex: 0 0 100px; border: 1px solid #000000; border-bottom: none">
                    1801006
                  </div>
                </div>
              </caption>
              <tr>
                <th>Актив</th>
                <th>Код <br>
                  рядка
                </th>
                <th>
                  На початок <br>
                  звітного року
                </th>
                <th>
                  На кінець <br>
                  звітного періоду
                </th>
              </tr>
              <tr>
                <td class="text-center">
                  1
                </td>
                <td class="text-center">
                  2
                </td>
                <td class="text-center">
                  3
                </td>
                <td class="text-center">
                  4
                </td>
              </tr>
              <tr>
                <td class="text-center font-weight-bold">
                  I. Необоротні активи
                </td>
                <td class="text-center">
                </td>
                <td class="text-center">
                </td>
                <td class="text-center">
                </td>
              </tr>
              <tr>
                <td>
                  Нематеріальні активи:
                </td>
                <td>
                  1000
                </td>
                <td ref="row_1000_total_start">
                  {{ table_1.row_1000_total_start }}
                </td>
                <td ref="row_1000_total_end">
                  {{ table_1.row_1000_total_end }}
                </td>
              </tr>
              <tr>
                <td class="pl-5">
                  первісна вартість
                </td>
                <td>
                  1001
                </td>
                <td >
                  <input type="text"
                         ref="row_1001_start"
                         @input="onValueInput($event, 'table_1.row_1001_start')"
                         @change="getTable1Total"
                         v-decimal
                         @focusin="onInputFocusIn($event, 'table_1.row_1001_start')"
                         @focusout="onInputFocusOut($event, 'table_1.row_1001_start')"
                         class="regulatory-report-input text-center">
                </td>
                <td >
                  <input type="text"
                         ref="row_1001_end"
                         @input="onValueInput($event, 'table_1.row_1001_end')"
                         @change="getTable1Total"
                         v-decimal
                         @focusin="onInputFocusIn($event, 'table_1.row_1001_end')"
                         @focusout="onInputFocusOut($event, 'table_1.row_1001_end')"
                         class="regulatory-report-input text-center">
                </td>
              </tr>
              <tr>
                <td class="pl-5">
                  накопичена амортизація
                </td>
                <td style="width: 100; max-width: 100px">
                  1002
                </td>
                <td>
                  (<input type="text"
                          ref="row_1002_start"
                          @input="onValueInput($event, 'table_1.row_1002_start')"
                         @change="getTable1Total"
                         v-decimal
                          @focusin="onInputFocusIn($event, 'table_1.row_1002_start')"
                          @focusout="onInputFocusOut($event, 'table_1.row_1002_start')"
                         class="regulatory-report-input text-center">)
                </td>
                <td>
                  (<input type="text"
                          ref="row_1002_end"
                          @input="onValueInput($event, 'table_1.row_1002_end')"
                         @change="getTable1Total"
                          @focusin="onInputFocusIn($event, 'table_1.row_1002_end')"
                          @focusout="onInputFocusOut($event, 'table_1.row_1002_end')"
                         v-decimal
                         class="regulatory-report-input text-center">)
                </td>
              </tr>
              <tr>
                <td>
                  Незавершені капітальні інвестиції
                </td>
                <td>
                  1005
                </td>
                <td>
                  <input type="text"
                         @input="onValueInput($event, 'table_1.row_1005_start')"
                         ref="row_1005_start"
                         @focusin="onInputFocusIn($event, 'table_1.row_1005_start')"
                         @focusout="onInputFocusOut($event, 'table_1.row_1005_start')"
                         @change="getTable1Total"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
                <td>
                  <input type="text"
                         @input="onValueInput($event, 'table_1.row_1005_end')"
                         ref="row_1005_end"
                         @focusin="onInputFocusIn($event, 'table_1.row_1005_end')"
                         @focusout="onInputFocusOut($event, 'table_1.row_1005_end')"
                         @change="getTable1Total"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
              </tr>
              <tr>
                <td>
                  Основні засоби:
                </td>
                <td>
                  1010
                </td>
                <td  ref="row_1010_total_start">
                  {{ table_1.row_1010_total_start }}
                </td>
                <td  ref="row_1010_total_end">
                  {{ table_1.row_1010_total_end }}
                </td>
              </tr>
              <tr>
                <td class="pl-5">
                  первісна вартість
                </td>
                <td>
                  1011
                </td>
                <td>
                  <input type="text"
                         @input="onValueInput($event, 'table_1.row_1011_start')"
                         ref="row_1011_start"
                         @focusin="onInputFocusIn($event, 'table_1.row_1011_start')"
                         @focusout="onInputFocusOut($event, 'table_1.row_1011_start')"
                         @change="getTable1Total"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
                <td>
                  <input type="text"
                         @input="onValueInput($event, 'table_1.row_1011_end')"
                         @change="getTable1Total"
                         ref="row_1011_end"
                         @focusin="onInputFocusIn($event, 'table_1.row_1011_end')"
                         @focusout="onInputFocusOut($event, 'table_1.row_1011_end')"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
              </tr>
              <tr>
                <td class="pl-5">
                  знос
                </td>
                <td>
                  1012
                </td>
                <td>
                  (
                  <input type="text"
                         @input="onValueInput($event, 'table_1.row_1012_start')"
                         ref="row_1012_start"
                         @focusin="onInputFocusIn($event, 'table_1.row_1012_start')"
                         @focusout="onInputFocusOut($event, 'table_1.row_1012_start')"
                         @change="getTable1Total"
                         v-decimal
                         class="regulatory-report-input text-center">
                  )
                </td>
                <td>
                  (
                  <input type="text"
                         @input="onValueInput($event, 'table_1.row_1012_end')"
                         ref="row_1012_end"
                         @focusin="onInputFocusIn($event, 'table_1.row_1012_end')"
                         @focusout="onInputFocusOut($event, 'table_1.row_1012_end')"
                         @change="getTable1Total"
                         v-decimal
                         class="regulatory-report-input text-center">
                  )
                </td>
              </tr>
              <tr>
                <td>
                  Довгострокові біологічні активи
                </td>
                <td>
                  1020
                </td>
                <td>
                  <input type="text"
                         @input="onValueInput($event, 'table_1.row_1020_start')"
                         ref="row_1020_start"
                         @focusin="onInputFocusIn($event, 'table_1.row_1020_start')"
                         @focusout="onInputFocusOut($event, 'table_1.row_1020_start')"
                         @change="getTable1Total"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
                <td>
                  <input type="text"
                         @input="onValueInput($event, 'table_1.row_1020_end')"
                         ref="row_1020_end"
                         @focusin="onInputFocusIn($event, 'table_1.row_1020_end')"
                         @focusout="onInputFocusOut($event, 'table_1.row_1020_end')"
                         @change="getTable1Total"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
              </tr>
              <tr>
                <td>
                  Довгострокові фінансові інвестиції
                </td>
                <td>
                  1030
                </td>
                <td>
                  <input type="text"
                         @input="onValueInput($event, 'table_1.row_1030_start')"
                         ref="row_1030_start"
                         @focusin="onInputFocusIn($event, 'table_1.row_1030_start')"
                         @focusout="onInputFocusOut($event, 'table_1.row_1030_start')"
                         @change="getTable1Total"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
                <td>
                  <input type="text"
                         @input="onValueInput($event, 'table_1.row_1030_end')"
                         ref="row_1030_end"
                         @focusin="onInputFocusIn($event, 'table_1.row_1030_end')"
                         @focusout="onInputFocusOut($event, 'table_1.row_1030_end')"
                         @change="getTable1Total"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
              </tr>
              <tr>
                <td>
                  Інші необоротні активи
                </td>
                <td>
                  1090
                </td>
                <td>
                  <input type="text"
                         @input="onValueInput($event, 'table_1.row_1090_start')"
                         ref="row_1090_start"
                         @focusin="onInputFocusIn($event, 'table_1.row_1090_start')"
                         @focusout="onInputFocusOut($event, 'table_1.row_1090_start')"
                         @change="getTable1Total"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
                <td>
                  <input type="text"
                         @input="onValueInput($event, 'table_1.row_1090_end')"
                         ref="row_1090_end"
                         @focusin="onInputFocusIn($event, 'table_1.row_1090_end')"
                         @focusout="onInputFocusOut($event, 'table_1.row_1090_end')"
                         @change="getTable1Total"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
              </tr>
              <tr>
                <td class="font-weight-bold">
                  Усього за розділом I
                </td>
                <td class="font-weight-bold">
                  1095
                </td>
                <td class="font-weight-bold" ref="row_1095_total_start">
                  {{ table_1.row_1095_total_start }}
                </td>
                <td class="font-weight-bold" ref="row_1095_total_end">
                  {{ table_1.row_1095_total_end }}
                </td>
              </tr>
              <tr>
                <td class="font-weight-bold text-center">
                  II. Оборотні активи
                </td>
                <td>

                </td>
                <td class="text-center" style="width: 200; max-width: 200px">
                </td>
                <td class="text-center" style="width: 200; max-width: 200px">
                </td>
              </tr>
              <tr>
                <td>
                  Запаси:
                </td>
                <td>
                  1100
                </td>
                <td>
                  <input type="text"
                         @input="onValueInput($event, 'table_1.row_1100_start')"
                         ref="row_1100_start"
                         @focusin="onInputFocusIn($event, 'table_1.row_1100_start')"
                         @focusout="onInputFocusOut($event, 'table_1.row_1100_start')"
                         @change="getTable1Total"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
                <td>
                  <input type="text"
                         @input="onValueInput($event, 'table_1.row_1100_end')"
                         ref="row_1100_end"
                         @focusin="onInputFocusIn($event, 'table_1.row_1100_end')"
                         @focusout="onInputFocusOut($event, 'table_1.row_1100_end')"
                         @change="getTable1Total"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
              </tr>
              <tr>
                <td class="pl-5">
                  у тому числі готова продукція
                </td>
                <td>
                  1103
                </td>
                <td>
                  <input type="text"
                         @input="onValueInput($event, 'table_1.row_1103_start')"
                         ref="row_1103_start"
                         @focusin="onInputFocusIn($event, 'table_1.row_1103_start')"
                         @focusout="onInputFocusOut($event, 'table_1.row_1103_start')"
                         @change="getTable1Total"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
                <td>
                  <input type="text"
                         @input="onValueInput($event, 'table_1.row_1103_end')"
                         ref="row_1103_end"
                         @focusin="onInputFocusIn($event, 'table_1.row_1103_end')"
                         @focusout="onInputFocusOut($event, 'table_1.row_1103_end')"
                         @change="getTable1Total"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
              </tr>
              <tr>
                <td>
                  Поточні біологічні активи
                </td>
                <td>
                  1110
                </td>
                <td>
                  <input type="text"
                         @input="onValueInput($event, 'table_1.row_1110_start')"
                         ref="row_1110_start"
                         @focusin="onInputFocusIn($event, 'table_1.row_1110_start')"
                         @focusout="onInputFocusOut($event, 'table_1.row_1110_start')"
                         @change="getTable1Total"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
                <td>
                  <input type="text"
                         @input="onValueInput($event, 'table_1.row_1110_end')"
                         ref="row_1110_end"
                         @focusin="onInputFocusIn($event, 'table_1.row_1110_end')"
                         @focusout="onInputFocusOut($event, 'table_1.row_1110_end')"
                         @change="getTable1Total"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
              </tr>
              <tr>
                <td>
                  Дебіторська заборгованість за товари, роботи, послуги
                </td>
                <td>
                  1125
                </td>
                <td>
                  <input type="text"
                         @input="onValueInput($event, 'table_1.row_1125_start')"
                         ref="row_1125_start"
                         @focusin="onInputFocusIn($event, 'table_1.row_1125_start')"
                         @focusout="onInputFocusOut($event, 'table_1.row_1125_start')"
                         @change="getTable1Total"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
                <td>
                  <input type="text"
                         @input="onValueInput($event, 'table_1.row_1125_end')"
                         ref="row_1125_end"
                         @focusin="onInputFocusIn($event, 'table_1.row_1125_end')"
                         @focusout="onInputFocusOut($event, 'table_1.row_1125_end')"
                         @change="getTable1Total"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
              </tr>
              <tr>
                <td>
                  Дебіторська заборгованість за розрахунками з бюджетом:
                </td>
                <td>
                  1135
                </td>
                <td>
                  <input type="text"
                         @input="onValueInput($event, 'table_1.row_1135_start')"
                         ref="row_1135_start"
                         @focusin="onInputFocusIn($event, 'table_1.row_1135_start')"
                         @focusout="onInputFocusOut($event, 'table_1.row_1135_start')"
                         @change="getTable1Total"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
                <td>
                  <input type="text"
                         @input="onValueInput($event, 'table_1.row_1135_end')"
                         ref="row_1135_end"
                         @focusin="onInputFocusIn($event, 'table_1.row_1135_end')"
                         @focusout="onInputFocusOut($event, 'table_1.row_1135_end')"
                         @change="getTable1Total"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
              </tr>
              <tr>
                <td class="pl-5">
                  у тому числі з податку на прибуток
                </td>
                <td>
                  1136
                </td>
                <td>
                  <input type="text"
                         @input="onValueInput($event, 'table_1.row_1136_start')"
                         ref="row_1136_start"
                         @focusin="onInputFocusIn($event, 'table_1.row_1136_start')"
                         @focusout="onInputFocusOut($event, 'table_1.row_1136_start')"
                         @change="getTable1Total"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
                <td>
                  <input type="text"
                         @input="onValueInput($event, 'table_1.row_1136_end')"
                         ref="row_1136_end"
                         @focusin="onInputFocusIn($event, 'table_1.row_1136_end')"
                         @focusout="onInputFocusOut($event, 'table_1.row_1136_end')"
                         @change="getTable1Total"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
              </tr>
              <tr>
                <td>
                  Інша поточна дебіторська заборгованість
                </td>
                <td>
                  1155
                </td>
                <td>
                  <input type="text"
                         @input="onValueInput($event, 'table_1.row_1155_start')"
                         ref="row_1155_start"
                         @focusin="onInputFocusIn($event, 'table_1.row_1155_start')"
                         @focusout="onInputFocusOut($event, 'table_1.row_1155_start')"
                         @change="getTable1Total"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
                <td>
                  <input type="text"
                         @input="onValueInput($event, 'table_1.row_1155_end')"
                         ref="row_1155_end"
                         @focusin="onInputFocusIn($event, 'table_1.row_1155_end')"
                         @focusout="onInputFocusOut($event, 'table_1.row_1155_end')"
                         @change="getTable1Total"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
              </tr>
              <tr>
                <td>
                  Поточні фінансові інвестиції
                </td>
                <td>
                  1160
                </td>
                <td>
                  <input type="text"
                         @input="onValueInput($event, 'table_1.row_1160_start')"
                         ref="row_1160_start"
                         @focusin="onInputFocusIn($event, 'table_1.row_1160_start')"
                         @focusout="onInputFocusOut($event, 'table_1.row_1160_start')"
                         @change="getTable1Total"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
                <td>
                  <input type="text"
                         @input="onValueInput($event, 'table_1.row_1160_end')"
                         ref="row_1160_end"
                         @focusin="onInputFocusIn($event, 'table_1.row_1160_end')"
                         @focusout="onInputFocusOut($event, 'table_1.row_1160_end')"
                         @change="getTable1Total"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
              </tr>
              <tr>
                <td>
                  Гроші та їх еквіваленти
                </td>
                <td>
                  1165
                </td>
                <td>
                  <input type="text"
                         @input="onValueInput($event, 'table_1.row_1165_start')"
                         ref="row_1165_start"
                         @focusin="onInputFocusIn($event, 'table_1.row_1165_start')"
                         @focusout="onInputFocusOut($event, 'table_1.row_1165_start')"
                         @change="getTable1Total"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
                <td>
                  <input type="text"
                         @input="onValueInput($event, 'table_1.row_1165_end')"
                         ref="row_1165_end"
                         @focusin="onInputFocusIn($event, 'table_1.row_1165_end')"
                         @focusout="onInputFocusOut($event, 'table_1.row_1165_end')"
                         @change="getTable1Total"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
              </tr>
              <tr>
                <td>
                  Витрати майбутніх періодів
                </td>
                <td>
                  1170
                </td>
                <td>
                  <input type="text"
                         @input="onValueInput($event, 'table_1.row_1170_start')"
                         ref="row_1170_start"
                         @focusin="onInputFocusIn($event, 'table_1.row_1170_start')"
                         @focusout="onInputFocusOut($event, 'table_1.row_1170_start')"
                         @change="getTable1Total"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
                <td>
                  <input type="text"
                         @input="onValueInput($event, 'table_1.row_1170_end')"
                         ref="row_1170_end"
                         @focusin="onInputFocusIn($event, 'table_1.row_1170_end')"
                         @focusout="onInputFocusOut($event, 'table_1.row_1170_end')"
                         @change="getTable1Total"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
              </tr>
              <tr>
                <td>
                  Інші оборотні активи
                </td>
                <td>
                  1190
                </td>
                <td>
                  <input type="text"
                         @input="onValueInput($event, 'table_1.row_1190_start')"
                         ref="row_1190_start"
                         @focusin="onInputFocusIn($event, 'table_1.row_1190_start')"
                         @focusout="onInputFocusOut($event, 'table_1.row_1190_start')"
                         @change="getTable1Total"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
                <td>
                  <input type="text"
                         @input="onValueInput($event, 'table_1.row_1190_end')"
                         ref="row_1190_end"
                         @focusin="onInputFocusIn($event, 'table_1.row_1190_end')"
                         @focusout="onInputFocusOut($event, 'table_1.row_1190_end')"
                         @change="getTable1Total"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
              </tr>
              <tr>
                <td class="font-weight-bold">
                  Усього за розділом II
                </td>
                <td class="font-weight-bold">
                  1195
                </td>
                <td class="font-weight-bold" ref="row_1195_total_start">
                  {{ table_1.row_1195_total_start }}
                </td>
                <td class="font-weight-bold" ref="row_1195_total_end">
                 {{ table_1.row_1195_total_end }}
                </td>
              </tr>
              <tr>
                <td class="font-weight-bold text-center">
                  III. Необоротні активи, утримувані для продажу, та групи вибуття
                </td>
                <td class="font-weight-bold">
                  1200
                </td>
                <td class="font-weight-bold">
                  <input type="text"
                         @input="onValueInput($event, 'table_1.row_1200_total_start')"
                         ref="row_1200_total_start"
                         @focusin="onInputFocusIn($event, 'table_1.row_1200_total_start')"
                         @focusout="onInputFocusOut($event, 'table_1.row_1200_total_start')"
                         @change="getTable1Total"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
                <td class="font-weight-bold">
                  <input type="text"
                         @input="onValueInput($event, 'table_1.row_1200_total_end')"
                         ref="row_1200_total_end"
                         @focusin="onInputFocusIn($event, 'table_1.row_1200_total_end')"
                         @focusout="onInputFocusOut($event, 'table_1.row_1200_total_end')"
                         @change="getTable1Total"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
              </tr>
              <tr>
                <td class="font-weight-bold">
                  Баланс
                </td>
                <td class="font-weight-bold">
                  1300
                </td>
                <td class="font-weight-bold" ref="row_1300_balance_start">
                  {{ table_1.row_1300_balance_start }}
                </td>
                <td class="font-weight-bold" ref="row_1300_balance_end">
                  {{ table_1.row_1300_balance_end }}
                </td>
              </tr>
              <tr>
                <td class="font-weight-bold text-center">Пасив</td>
                <td class="font-weight-bold text-center">Код <br>
                  рядка
                </td>
                <td class="font-weight-bold text-center">
                  На початок <br>
                  звітного року
                </td>
                <td class="font-weight-bold text-center">
                  На кінець <br>
                  звітного періоду
                </td>
              </tr>
              <tr>
                <td class="text-center">
                  1
                </td>
                <td class="text-center">
                  2
                </td>
                <td class="text-center">
                  3
                </td>
                <td class="text-center">
                  4
                </td>
              </tr>
              <tr>
                <td class="text-center font-weight-bold">
                  I. Власний капітал
                </td>
                <td class="text-center">
                </td>
                <td class="text-center">
                </td>
                <td class="text-center">
                </td>
              </tr>
              <tr>
                <td>
                  Зареєстрований (пайовий) капітал
                </td>
                <td>
                  1400
                </td>
                <td>
                  <input type="text"
                         @input="onValueInput($event, 'table_1.row_1400_start')"
                         ref="row_1400_start"
                         @focusin="onInputFocusIn($event, 'table_1.row_1400_start')"
                         @focusout="onInputFocusOut($event, 'table_1.row_1400_start')"
                         @change="getTable1Total"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
                <td>
                  <input type="text"
                         @input="onValueInput($event, 'table_1.row_1400_end')"
                         ref="row_1400_end"
                         @focusin="onInputFocusIn($event, 'table_1.row_1400_end')"
                         @focusout="onInputFocusOut($event, 'table_1.row_1400_end')"
                         @change="getTable1Total"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
              </tr>
              <tr>
                <td>
                  Додатковий капітал
                </td>
                <td>
                  1410
                </td>
                <td>
                  <input type="text"
                         @input="onValueInput($event, 'table_1.row_1410_start')"
                         ref="row_1410_start"
                         @focusin="onInputFocusIn($event, 'table_1.row_1410_start')"
                         @focusout="onInputFocusOut($event, 'table_1.row_1410_start')"
                         @change="getTable1Total"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
                <td>
                  <input type="text"
                         @input="onValueInput($event, 'table_1.row_1410_end')"
                         ref="row_1410_end"
                         @focusin="onInputFocusIn($event, 'table_1.row_1410_end')"
                         @focusout="onInputFocusOut($event, 'table_1.row_1410_end')"
                         @change="getTable1Total"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
              </tr>
              <tr>
                <td>
                  Резервний капітал
                </td>
                <td>
                  1415
                </td>
                <td>
                  <input type="text"
                         @input="onValueInput($event, 'table_1.row_1415_start')"
                         ref="row_1415_start"
                         @focusin="onInputFocusIn($event, 'table_1.row_1415_start')"
                         @focusout="onInputFocusOut($event, 'table_1.row_1415_start')"
                         @change="getTable1Total"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
                <td>
                  <input type="text"
                         @input="onValueInput($event, 'table_1.row_1415_end')"
                         ref="row_1415_end"
                         @focusin="onInputFocusIn($event, 'table_1.row_1415_end')"
                         @focusout="onInputFocusOut($event, 'table_1.row_1415_end')"
                         @change="getTable1Total"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
              </tr>
              <tr>
                <td>
                  Нерозподілений прибуток (непокритий збиток)
                </td>
                <td>
                  1420
                </td>
                <td>
                  <template v-if="table_1.row_1420_start < 0">(</template>
                  <input type="text"
                         @input="onValueInput($event, 'table_1.row_1420_start')"
                         ref="row_1420_start"
                         @focusin="onInputFocusIn($event, 'table_1.row_1420_start')"
                         @focusout="onInputFocusOut($event, 'table_1.row_1420_start')"
                         @change="getTable1Total"
                         v-decimal
                         class="regulatory-report-input text-center">
                  <template v-if="table_1.row_1420_start < 0">)</template>
                </td>
                <td>
                  <template v-if="table_1.row_1420_end < 0">(</template>
                  <input type="text"
                         @input="onValueInput($event, 'table_1.row_1420_end')"
                         ref="row_1420_end"
                         @focusin="onInputFocusIn($event, 'table_1.row_1420_end')"
                         @focusout="onInputFocusOut($event, 'table_1.row_1420_end')"
                         @change="getTable1Total"
                         v-decimal
                         class="regulatory-report-input text-center">
                  <template v-if="table_1.row_1420_end < 0">)</template>
                </td>
              </tr>
              <tr>
                <td>
                  Неоплачений капітал
                </td>
                <td>
                  1425
                </td>
                <td>
                  (
                  <input type="text"
                         @input="onValueInput($event, 'table_1.row_1425_start')"
                         ref="row_1425_start"
                         @focusin="onInputFocusIn($event, 'table_1.row_1425_start')"
                         @focusout="onInputFocusOut($event, 'table_1.row_1425_start')"
                         @change="getTable1Total"
                         v-decimal
                         class="regulatory-report-input text-center">
                  )
                </td>
                <td>
                  (
                  <input type="text"
                         @input="onValueInput($event, 'table_1.row_1425_end')"
                         ref="row_1425_end"
                         @focusin="onInputFocusIn($event, 'table_1.row_1425_end')"
                         @focusout="onInputFocusOut($event, 'table_1.row_1425_end')"
                         @change="getTable1Total"
                         v-decimal
                         class="regulatory-report-input text-center">
                  )
                </td>
              </tr>
              <tr>
                <td class="font-weight-bold">
                  Усього за розділом I
                </td>
                <td class="font-weight-bold">
                  1495
                </td>
                <td class="font-weight-bold" ref="row_1495_total_start">
                  <template v-if="table_1.row_1495_total_start < 0">(</template>
                  <span style="width: 86%; display: inline-block;" ref="row_1495_total_end">
                    {{ table_1.row_1495_total_start }}
                  </span>
                  <template v-if="table_1.row_1495_total_start < 0">)</template>
                </td>
                <td class="font-weight-bold">
                  <template v-if="table_1.row_1495_total_end < 0">(</template>
                  <span style="width: 86%; display: inline-block;" ref="row_1495_total_end">
                    {{ table_1.row_1495_total_end }}
                  </span>
                  <template v-if="table_1.row_1495_total_end < 0">)</template>
                </td>
              </tr>
              <tr>
                <td class="text-center font-weight-bold">
                  II. Довгострокові зобов'язання, цільове фінансування та
                  забезпечення
                </td>
                <td class="text-center font-weight-bold">
                  1595
                </td>
                <td class="font-weight-bold">
                  <input type="text"
                         @input="onValueInput($event, 'table_1.row_1595_total_start')"
                         ref="row_1595_total_start"
                         @focusin="onInputFocusIn($event, 'table_1.row_1595_total_start')"
                         @focusout="onInputFocusOut($event, 'table_1.row_1595_total_start')"
                         @change="getTable1Total"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
                <td class="font-weight-bold">
                  <input type="text"
                         @input="onValueInput($event, 'table_1.row_1595_total_end')"
                         ref="row_1595_total_end"
                         @focusin="onInputFocusIn($event, 'table_1.row_1595_total_end')"
                         @focusout="onInputFocusOut($event, 'table_1.row_1595_total_end')"
                         @change="getTable1Total"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
              </tr>
              <tr>
                <td class="text-center font-weight-bold">
                  III. Поточні зобов'язання
                </td>
                <td class="text-center font-weight-bold">
                </td>
                <td class="font-weight-bold">
                </td>
                <td class="font-weight-bold">
                </td>
              </tr>
              <tr>
                <td>
                  Короткострокові кредити банків
                </td>
                <td>
                  1600
                </td>
                <td>
                  <input type="text"
                         @input="onValueInput($event, 'table_1.row_1600_start')"
                         ref="row_1600_start"
                         @focusin="onInputFocusIn($event, 'table_1.row_1600_start')"
                         @focusout="onInputFocusOut($event, 'table_1.row_1600_start')"
                         @change="getTable1Total"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
                <td>
                  <input type="text"
                         @input="onValueInput($event, 'table_1.row_1600_end')"
                         ref="row_1600_end"
                         @focusin="onInputFocusIn($event, 'table_1.row_1600_end')"
                         @focusout="onInputFocusOut($event, 'table_1.row_1600_end')"
                         @change="getTable1Total"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
              </tr>
              <tr>
                <td>
                  Поточна кредиторська заборгованість за
                  довгостроковими зобов'язаннями:
                </td>
                <td>
                  1610
                </td>
                <td>
                  <input type="text"
                         @input="onValueInput($event, 'table_1.row_1610_start')"
                         ref="row_1610_start"
                         @focusin="onInputFocusIn($event, 'table_1.row_1610_start')"
                         @focusout="onInputFocusOut($event, 'table_1.row_1610_start')"
                         @change="getTable1Total"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
                <td>
                  <input type="text"
                         @input="onValueInput($event, 'table_1.row_1610_end')"
                         ref="row_1610_end"
                         @focusin="onInputFocusIn($event, 'table_1.row_1610_end')"
                         @focusout="onInputFocusOut($event, 'table_1.row_1610_end')"
                         @change="getTable1Total"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
              </tr>
              <tr>
                <td class="pl-5">
                  товари, роботи, послуги
                </td>
                <td>
                  1615
                </td>
                <td>
                  <input type="text"
                         @input="onValueInput($event, 'table_1.row_1615_start')"
                         ref="row_1615_start"
                         @focusin="onInputFocusIn($event, 'table_1.row_1615_start')"
                         @focusout="onInputFocusOut($event, 'table_1.row_1615_start')"
                         @change="getTable1Total"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
                <td>
                  <input type="text"
                         @input="onValueInput($event, 'table_1.row_1615_end')"
                         ref="row_1615_end"
                         @focusin="onInputFocusIn($event, 'table_1.row_1615_end')"
                         @focusout="onInputFocusOut($event, 'table_1.row_1615_end')"
                         @change="getTable1Total"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
              </tr>
              <tr>
                <td class="pl-5">
                  розрахунками з бюджетом
                </td>
                <td>
                  1620
                </td>
                <td>
                  <input type="text"
                         @input="onValueInput($event, 'table_1.row_1620_start')"
                         ref="row_1620_start"
                         @focusin="onInputFocusIn($event, 'table_1.row_1620_start')"
                         @focusout="onInputFocusOut($event, 'table_1.row_1620_start')"
                         @change="getTable1Total"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
                <td>
                  <input type="text"
                         @input="onValueInput($event, 'table_1.row_1620_end')"
                         ref="row_1620_end"
                         @focusin="onInputFocusIn($event, 'table_1.row_1620_end')"
                         @focusout="onInputFocusOut($event, 'table_1.row_1620_end')"
                         @change="getTable1Total"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
              </tr>
              <tr>
                <td class="pl-10">
                  у тому числі з податку на прибуток
                </td>
                <td>
                  1621
                </td>
                <td>
                  <input type="text"
                         @input="onValueInput($event, 'table_1.row_1621_start')"
                         ref="row_1621_start"
                         @focusin="onInputFocusIn($event, 'table_1.row_1621_start')"
                         @focusout="onInputFocusOut($event, 'table_1.row_1621_start')"
                         @change="getTable1Total"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
                <td>
                  <input type="text"
                         @input="onValueInput($event, 'table_1.row_1621_end')"
                         ref="row_1621_end"
                         @focusin="onInputFocusIn($event, 'table_1.row_1621_end')"
                         @focusout="onInputFocusOut($event, 'table_1.row_1621_end')"
                         @change="getTable1Total"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
              </tr>
              <tr>
                <td class="pl-5">
                  розрахунками зі страхування
                </td>
                <td>
                  1625
                </td>
                <td>
                  <input type="text"
                         @input="onValueInput($event, 'table_1.row_1625_start')"
                         ref="row_1625_start"
                         @focusin="onInputFocusIn($event, 'table_1.row_1625_start')"
                         @focusout="onInputFocusOut($event, 'table_1.row_1625_start')"
                         @change="getTable1Total"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
                <td>
                  <input type="text"
                         @input="onValueInput($event, 'table_1.row_1625_end')"
                         ref="row_1625_end"
                         @focusin="onInputFocusIn($event, 'table_1.row_1625_end')"
                         @focusout="onInputFocusOut($event, 'table_1.row_1625_end')"
                         @change="getTable1Total"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
              </tr>
              <tr>
                <td class="pl-5">
                  розрахунками з оплати праці
                </td>
                <td>
                  1630
                </td>
                <td>
                  <input type="text"
                         @input="onValueInput($event, 'table_1.row_1630_start')"
                         ref="row_1630_start"
                         @focusin="onInputFocusIn($event, 'table_1.row_1630_start')"
                         @focusout="onInputFocusOut($event, 'table_1.row_1630_start')"
                         @change="getTable1Total"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
                <td>
                  <input type="text"
                         @input="onValueInput($event, 'table_1.row_1630_end')"
                         ref="row_1630_end"
                         @focusin="onInputFocusIn($event, 'table_1.row_1630_end')"
                         @focusout="onInputFocusOut($event, 'table_1.row_1630_end')"
                         @change="getTable1Total"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
              </tr>
              <tr>
                <td>
                  Доходи майбутніх періодів
                </td>
                <td>
                  1665
                </td>
                <td>
                  <input type="text"
                         @input="onValueInput($event, 'table_1.row_1665_start')"
                         ref="row_1665_start"
                         @focusin="onInputFocusIn($event, 'table_1.row_1665_start')"
                         @focusout="onInputFocusOut($event, 'table_1.row_1665_start')"
                         @change="getTable1Total"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
                <td>
                  <input type="text"
                         @input="onValueInput($event, 'table_1.row_1665_end')"
                         ref="row_1665_end"
                         @focusin="onInputFocusIn($event, 'table_1.row_1665_end')"
                         @focusout="onInputFocusOut($event, 'table_1.row_1665_end')"
                         @change="getTable1Total"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
              </tr>
              <tr>
                <td>
                  Інші поточні зобов'язання
                </td>
                <td>
                  1690
                </td>
                <td>
                  <input type="text"
                         @input="onValueInput($event, 'table_1.row_1690_start')"
                         ref="row_1690_start"
                         @focusin="onInputFocusIn($event, 'table_1.row_1690_start')"
                         @focusout="onInputFocusOut($event, 'table_1.row_1690_start')"
                         @change="getTable1Total"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
                <td>
                  <input type="text"
                         @input="onValueInput($event, 'table_1.row_1690_end')"
                         ref="row_1690_end"
                         @focusin="onInputFocusIn($event, 'table_1.row_1690_end')"
                         @focusout="onInputFocusOut($event, 'table_1.row_1690_end')"
                         @change="getTable1Total"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
              </tr>
              <tr>
                <td class="font-weight-bold">
                  Усього за розділом III
                </td>
                <td class="font-weight-bold">
                  1695
                </td>
                <td class="font-weight-bold" ref="row_1695_total_start">
                  {{ table_1.row_1695_total_start }}
                </td>
                <td class="font-weight-bold" ref="row_1695_total_end">
                  {{ table_1.row_1695_total_end }}
                </td>
              </tr>
              <tr>
                <td class="font-weight-bold text-center">
                  IV. Зобов'язання, пов'язані з необоротними активами,
                  утримуваними для продажу, та групами вибуття
                </td>
                <td class="font-weight-bold">
                  1700
                </td>
                <td class="font-weight-bold">
                  <input type="text"
                         @input="onValueInput($event, 'table_1.row_1700_total_start')"
                         ref="row_1700_total_start"
                         @focusin="onInputFocusIn($event, 'table_1.row_1700_total_start')"
                         @focusout="onInputFocusOut($event, 'table_1.row_1700_total_start')"
                         @change="getTable1Total"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
                <td class="font-weight-bold">
                  <input type="text"
                         @input="onValueInput($event, 'table_1.row_1700_total_end')"
                         ref="row_1700_total_end"
                         @focusin="onInputFocusIn($event, 'table_1.row_1700_total_end')"
                         @focusout="onInputFocusOut($event, 'table_1.row_1700_total_end')"
                         @change="getTable1Total"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
              </tr>
              <tr>
                <td class="font-weight-bold">
                  Баланс
                </td>
                <td class="font-weight-bold">
                  1900
                </td>
                <td class="font-weight-bold" ref="row_1900_balance_start">
                  {{ table_1.row_1900_balance_start }}
                </td>
                <td class="font-weight-bold" ref="row_1900_balance_end">
                  {{ table_1.row_1900_balance_end }}
                </td>
              </tr>
            </table>

            <table class="regulatory-report-table" style="width: 750px; margin-top: 18px">
              <caption>
                <div
                     style="font-size: 14px; margin-top: 20px; margin-bottom: 20px; line-height: 20px; font-weight: bold; text-align: center">
                  2. Звіт про фінансові результати за {{ financialResultDate }}
                </div>
                <div class="d-flex" style="text-align: center">
                  <div style="flex: 1"></div>
                  <div style="flex: 0 0 72px">
                    Форма № 2-м
                  </div>
                  <div style="flex: 0 0 90px">
                    Код за ДКУД
                  </div>
                  <div style="flex: 0 0 100px; border: 1px solid #000000; border-bottom: none">
                    1801007
                  </div>
                </div>
              </caption>
              <thead>
              <tr>
                <th>Стаття</th>
                <th>Код рядка</th>
                <th>
                  За звітний період
                </th>
                <th>
                  За аналогічний період попереднього року
                </th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td class="text-center">
                  1
                </td>
                <td class="text-center">
                  2
                </td>
                <td class="text-center">
                  3
                </td>
                <td class="text-center">
                  4
                </td>
              </tr>
              <tr>
                <td>
                  Чистий дохід від реалізації продукції (товарів, робіт, послуг)
                </td>
                <td>
                  2000
                </td>
                <td>
                  <input type="text"
                         @input="onValueInput($event, 'table_2.row_2000_start')"
                         ref="row_2000_start"
                         @focusin="onInputFocusIn($event, 'table_2.row_2000_start')"
                         @focusout="onInputFocusOut($event, 'table_2.row_2000_start')"
                         @change="getTable2Total"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
                <td>
                  <input type="text"
                         @input="onValueInput($event, 'table_2.row_2000_end')"
                         ref="row_2000_end"
                         @focusin="onInputFocusIn($event, 'table_2.row_2000_end')"
                         @focusout="onInputFocusOut($event, 'table_2.row_2000_end')"
                         @change="getTable2Total"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
              </tr>
              <tr>
                <td>
                  Інші операційні доходи
                </td>
                <td>
                  2120
                </td>
                <td>
                  <input type="text"
                         @input="onValueInput($event, 'table_2.row_2120_start')"
                         ref="row_2120_start"
                         @focusin="onInputFocusIn($event, 'table_2.row_2120_start')"
                         @focusout="onInputFocusOut($event, 'table_2.row_2120_start')"
                         @change="getTable2Total"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
                <td>
                  <input type="text"
                         @input="onValueInput($event, 'table_2.row_2120_end')"
                         ref="row_2120_end"
                         @focusin="onInputFocusIn($event, 'table_2.row_2120_end')"
                         @focusout="onInputFocusOut($event, 'table_2.row_2120_end')"
                         @change="getTable2Total"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
              </tr>
              <tr>
                <td>
                  Інші доходи
                </td>
                <td>
                  2240
                </td>
                <td>
                  <input type="text"
                         @input="onValueInput($event, 'table_2.row_2240_start')"
                         ref="row_2240_start"
                         @focusin="onInputFocusIn($event, 'table_2.row_2240_start')"
                         @focusout="onInputFocusOut($event, 'table_2.row_2240_start')"
                         @change="getTable2Total"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
                <td>
                  <input type="text"
                         @input="onValueInput($event, 'table_2.row_2240_end')"
                         ref="row_2240_end"
                         @focusin="onInputFocusIn($event, 'table_2.row_2240_end')"
                         @focusout="onInputFocusOut($event, 'table_2.row_2240_end')"
                         @change="getTable2Total"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
              </tr>
              <tr>
                <td class="font-weight-bold">
                  Разом доходи (2000 + 2120 + 2240)
                </td>
                <td class="font-weight-bold">
                  2280
                </td>
                <td class="font-weight-bold" ref="row_2280_total_start">
                  {{ table_2.row_2280_total_start }}
                </td>
                <td class="font-weight-bold" ref="row_2280_total_end">
                  {{ table_2.row_2280_total_end }}
                </td>
              </tr>
              <tr>
                <td>
                  Собівартість реалізованої продукції (товарів, робіт, послуг)
                </td>
                <td>
                  2050
                </td>
                <td>
                  (
                  <input type="text"
                         @input="onValueInput($event, 'table_2.row_2050_start')"
                         ref="row_2050_start"
                         @focusin="onInputFocusIn($event, 'table_2.row_2050_start')"
                         @focusout="onInputFocusOut($event, 'table_2.row_2050_start')"
                         @change="getTable2Total"
                         v-decimal
                         class="regulatory-report-input text-center">
                  )
                </td>
                <td>
                  (
                  <input type="text"
                         @input="onValueInput($event, 'table_2.row_2050_end')"
                         ref="row_2050_end"
                         @focusin="onInputFocusIn($event, 'table_2.row_2050_end')"
                         @focusout="onInputFocusOut($event, 'table_2.row_2050_end')"
                         @change="getTable2Total"
                         v-decimal
                         class="regulatory-report-input text-center">
                  )
                </td>
              </tr>
              <tr>
                <td>
                  Інші операційні витрати
                </td>
                <td>
                  2180
                </td>
                <td>
                  (
                  <input type="text"
                         @input="onValueInput($event, 'table_2.row_2180_start')"
                         ref="row_2180_start"
                         @focusin="onInputFocusIn($event, 'table_2.row_2180_start')"
                         @focusout="onInputFocusOut($event, 'table_2.row_2180_start')"
                         @change="getTable2Total"
                         v-decimal
                         class="regulatory-report-input text-center">
                  )
                </td>
                <td>
                  (
                  <input type="text"
                         @input="onValueInput($event, 'table_2.row_2180_end')"
                         ref="row_2180_end"
                         @focusin="onInputFocusIn($event, 'table_2.row_2180_end')"
                         @focusout="onInputFocusOut($event, 'table_2.row_2180_end')"
                         @change="getTable2Total"
                         v-decimal
                         class="regulatory-report-input text-center">
                  )
                </td>
              </tr>
              <tr>
                <td>
                  Інші витрати
                </td>
                <td>
                  2270
                </td>
                <td>
                  (
                  <input type="text"
                         @input="onValueInput($event, 'table_2.row_2270_start')"
                         ref="row_2270_start"
                         @focusin="onInputFocusIn($event, 'table_2.row_2270_start')"
                         @focusout="onInputFocusOut($event, 'table_2.row_2270_start')"
                         @change="getTable2Total"
                         v-decimal
                         class="regulatory-report-input text-center">
                  )
                </td>
                <td>
                  (
                  <input type="text"
                         @input="onValueInput($event, 'table_2.row_2270_end')"
                         ref="row_2270_end"
                         @focusin="onInputFocusIn($event, 'table_2.row_2270_end')"
                         @focusout="onInputFocusOut($event, 'table_2.row_2270_end')"
                         @change="getTable2Total"
                         v-decimal
                         class="regulatory-report-input text-center">
                  )
                </td>
              </tr>
              <tr>
                <td class="font-weight-bold">
                  Разом витрати (2050 + 2180 + 2270)
                </td>
                <td class="font-weight-bold">
                  2285
                </td>
                <td class="font-weight-bold">
                  (
                  <span style="width: 86%; display: inline-block;" ref="row_2285_total_start">
                    {{ table_2.row_2285_total_start }}
                  </span>
                  )
                </td>
                <td class="font-weight-bold">
                  (
                  <span style="width: 86%; display: inline-block;" ref="row_2285_total_end">
                    {{ table_2.row_2285_total_end }}
                  </span>
                  )
                </td>
              </tr>
              <tr>
                <td>
                  Фінансовий результат до оподаткування (2280 - 2285)
                </td>
                <td>
                  2290
                </td>
                <td>
                  <template v-if="table_2.row_2290_total_start < 0">(</template>
                  <span style="width: 86%; display: inline-block;" ref="row_2290_total_start">
                    {{ table_2.row_2290_total_start }}
                  </span>
                  <template v-if="table_2.row_2290_total_start < 0">)</template>
                </td>
                <td>
                  <template v-if="table_2.row_2290_total_end < 0">(</template>
                  <span style="width: 86%; display: inline-block;" ref="row_2290_total_end">
                    {{ table_2.row_2290_total_end }}
                  </span>
                  <template v-if="table_2.row_2290_total_end < 0">)</template>
                </td>
              </tr>
              <tr>
                <td>
                  Податок на прибуток
                </td>
                <td>
                  2300
                </td>
                <td>
                  (
                  <input type="text"
                         @input="onValueInput($event, 'table_2.row_2300_start')"
                         ref="row_2300_start"
                         @focusin="onInputFocusIn($event, 'table_2.row_2300_start')"
                         @focusout="onInputFocusOut($event, 'table_2.row_2300_start')"
                         @change="getTable2Total"
                         v-decimal
                         class="regulatory-report-input text-center">
                  )
                </td>
                <td>
                  (
                  <input type="text"
                         @input="onValueInput($event, 'table_2.row_2300_end')"
                         ref="row_2300_end"
                         @focusin="onInputFocusIn($event, 'table_2.row_2300_end')"
                         @focusout="onInputFocusOut($event, 'table_2.row_2300_end')"
                         @change="getTable2Total"
                         v-decimal
                         class="regulatory-report-input text-center">
                  )
                </td>
              </tr>
              <tr>
                <td class="font-weight-bold">
                  Чистий прибуток (збиток) (2290 - 2300)
                </td>
                <td class="font-weight-bold">
                  2350
                </td>
                <td class="font-weight-bold">
                  <template v-if="table_2.row_2350_total_start < 0">(</template>
                  <span style="width: 86%; display: inline-block;" ref="row_2350_total_start">
                    {{ table_2.row_2350_total_start }}
                  </span>
                  <template v-if="table_2.row_2350_total_start < 0">)</template>
                </td>
                <td class="font-weight-bold">
                  <template v-if="table_2.row_2350_total_end < 0">(</template>
                  <span style="width: 86%; display: inline-block;" ref="row_2350_total_end">
                    {{ table_2.row_2350_total_end }}
                  </span>
                  <template v-if="table_2.row_2350_total_end < 0">)</template>
                </td>
              </tr>
              </tbody>
            </table>
            <div style="width: 750px; margin-top: 20px; margin-bottom: 10px">
              <div style="display: flex; line-height: 18px; font-size: 14px;">
                <div style="flex: 0 0 160px; text-align: left">Керівник</div>
                <div style="flex: 0 0 100px"></div>
                <div style="flex: 1; border-bottom: 1px solid #000000"></div>
                <div style="flex: 0 0 100px"></div>
                <div style="flex: 0 0 250px;  text-align: center; border-bottom: 1px solid #000000; font-size: 12px">
                  {{ header.director_name }}
                </div>
              </div>
              <div style="display: flex; line-height: 20px; font-size: 12px; text-align: center">
                <div style="flex: 0 0 160px"></div>
                <div style="flex: 0 0 100px"></div>
                <div style="flex: 1">(підпис)</div>
                <div style="flex: 0 0 100px"></div>
                <div style="flex: 0 0 250px">(ініціали, прізвище)</div>
              </div>
            </div>
            <div style="width: 750px; margin-top: 10px; margin-bottom: 10px">
              <div style="display: flex; line-height: 18px; font-size: 14px;">
                <div style="flex: 0 0 160px; text-align: left">Головний бухгалтер</div>
                <div style="flex: 0 0 100px"></div>
                <div style="flex: 1; border-bottom: 1px solid #000000"></div>
                <div style="flex: 0 0 100px"></div>
                <div style="flex: 0 0 250px;  text-align: center; border-bottom: 1px solid #000000; font-size: 12px">
                  {{ header.accountant_name }}
                </div>
              </div>
              <div style="display: flex; line-height: 20px; font-size: 12px; text-align: center">
                <div style="flex: 0 0 160px"></div>
                <div style="flex: 0 0 100px"></div>
                <div style="flex: 1">(підпис)</div>
                <div style="flex: 0 0 100px"></div>
                <div style="flex: 0 0 250px">(ініціали, прізвище)</div>
              </div>
            </div>
            <div style="width: 750px; margin-top: 20px; margin-bottom: 10px; font-size: 11px">
              <sup>1</sup> Кодифікатор адміністративно-територіальних одиниць та територій територіальних громад.
            </div>
          </v-tab-item>
          <v-tab-item class="pa-0 non-printable" style="height: 100%;">
            <v-card>
              <v-card-text>
                <v-row>
                  <v-col cols="12" md="8">
                    <v-text-field
                        v-model="header.payer_name"
                        label="Підприємство: "
                        placeholder="Введіть назву підприємства"
                        filled hide-details
                        required :rules="[v => !!v || 'Це поле є обов’язковим']"
                        :class="header.payer_name ? '' : 'req-star'"
                        color="grey"
                    />
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field
                        v-model="header.payer_ipn_or_passport"
                        label="код ЄДРПОУ: "
                        placeholder="Введіть код ЄДРПОУ"
                        filled hide-details
                        required :rules="[v => !!v || 'Це поле є обов’язковим']"
                        :class="header.payer_ipn_or_passport ? '' : 'req-star'"
                        color="grey"
                    />
                  </v-col>
                  <v-col cols="12" md="8">
                    <v-text-field
                        v-model="header.payer_city_name"
                        label="Територія: "
                        placeholder="Введіть назву території"
                        filled hide-details
                        required :rules="[v => !!v || 'Це поле є обов’язковим']"
                        :class="header.payer_city_name ? '' : 'req-star'"
                        color="grey"
                    />
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field
                        v-model="header.payer_city_code"
                        label="Код КАТОТТГ: "
                        placeholder="Введіть код КАТОТТГ"
                        filled hide-details
                        required :rules="[v => !!v || 'Це поле є обов’язковим']"
                        :class="header.payer_city_code ? '' : 'req-star'"
                        color="grey"
                    />
                  </v-col>
                  <v-col cols="12" md="8">
                    <v-text-field
                        v-model="header.payer_organization_type"
                        label="Форма господарювання: "
                        placeholder="Введіть форму господарювання"
                        filled hide-details
                        required :rules="[v => !!v || 'Це поле є обов’язковим']"
                        :class="header.payer_organization_type ? '' : 'req-star'"
                        color="grey"
                    />
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field
                        v-model="header.payer_organization_type_code"
                        label="Код КОПФГ: "
                        placeholder="Введіть код КОПФГ"
                        filled hide-details
                        required :rules="[v => !!v || 'Це поле є обов’язковим']"
                        :class="header.payer_organization_type_code ? '' : 'req-star'"
                        color="grey"
                    />
                  </v-col>
                  <v-col cols="12" md="8">
                    <v-text-field
                        v-model="header.payer_kved_name"
                        label="Вид економічної діяльності: "
                        placeholder="Введіть вид економічної діяльності"
                        filled hide-details
                        required :rules="[v => !!v || 'Це поле є обов’язковим']"
                        :class="header.payer_kved_name ? '' : 'req-star'"
                        color="grey"
                    />
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field
                        v-model="header.payer_kved_code"
                        label="Код КВЕД: "
                        placeholder="Введіть код КВЕД"
                        filled hide-details
                        required :rules="[v => !!v || 'Це поле є обов’язковим']"
                        :class="header.payer_kved_code ? '' : 'req-star'"
                        color="grey"
                    />
                  </v-col>
                  <v-col cols="12" md="8">
                    <v-text-field
                        v-model="header.payer_postal_address"
                        label="Податкова адреса: "
                        placeholder="Введіть податкову адресу: "
                        filled hide-details
                        required :rules="[v => !!v || 'Це поле є обов’язковим']"
                        :class="header.payer_postal_address ? '' : 'req-star'"
                        color="grey"
                    />
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field
                        v-model="header.payer_phone"
                        label="Телефон: "
                        placeholder="Введіть телефон: "
                        filled hide-details
                        color="grey"
                    />
                  </v-col>
                  <v-col cols="12" md="8">
                    <v-text-field
                        v-model="header.director_name"
                        label="ПІБ керівника: "
                        placeholder="Введіть ПІБ керівника: "
                        required :rules="[v => !!v || 'Це поле є обов’язковим']"
                        :class="header.director_name ? '' : 'req-star'"
                        filled hide-details
                        color="grey"
                    />
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field
                        v-model="header.director_ipn"
                        label="ІПН керівника: "
                        placeholder="Введіть іпн керівника: "
                        required :rules="[v => !!v || 'Це поле є обов’язковим']"
                        :class="header.director_ipn ? '' : 'req-star'"
                        filled hide-details
                        color="grey"
                    />
                  </v-col>
                  <v-col cols="12" md="8">
                    <v-text-field
                        v-model="header.accountant_name"
                        label="ПІБ бухгалтера: "
                        placeholder="Введіть ПІБ бухгалтера: "
                        filled hide-details
                        color="grey"
                    />
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field
                        v-model="header.accountant_ipn"
                        label="ІПН бухгалтера: "
                        placeholder="Введіть іпн бухгалтера: "
                        filled hide-details
                        color="grey"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {
  regulatory_report_period_type_select,
  getRegulatoryReportPeriodType,
  endOfMonth, addMothsToStringDate, format_date_from_python, round_number, getCurrentDate
} from "@/utils/icons"
import {QUESTION_SHOW} from "@/store/actions/question";
import regulatoryAPI from "@/utils/axios/accounting/regulatory_reports";
import {ALERT_SHOW} from "@/store/actions/alert";
import {GET_ORGANIZATION_PRINT_DATA} from "@/store/actions/organization";
import {mapGetters} from "vuex";
import reportAPI from "@/utils/axios/reports";
export default {
  name: "small_balance_j0901107",
  props: {
    report_id: {
      type: Number,
      default: null
    },
    report_is_new: {
      type: Boolean,
      default: false
    },
    report_type: {
      type: String,
      default: ''
    }
  },
  components: {
    PrintReport: () => import("@/components/PrintReport")
  },
  computed: {
    ...mapGetters({
      organization: 'getCurrentOrganization',
      modalAnswer: 'question_answer'
    }),
    financialResultDate() {
      if (!this.header.period_type) {
        return ''
      }
      if (!this.header.year) {
        return ''
      }
      if (this.header.period_type === 'first_quarter') {
        return  `1 квартал ${this.header.year}`
      }
      if (this.header.period_type === 'half_year') {
        return `1 півріччя ${this.header.year}`
      }
      if (this.header.period_type === 'month_9') {
        return `9 місяців ${this.header.year}`
      }
      if (this.header.period_type === 'year') {
        return `${this.header.year}`
      }
      return null
    },
    balanceDate() {
      if (this.header.date_end) {
        return format_date_from_python(this.header.date_end)
      }
      return ''
    }
  },
  data() {
    return {
      modal_answer_watcher: null,
      print_data: {},
      report_state: {
        show: false,
        errors: []
      },
      regulatory_report_period_type_select,
      header: {
        fill_day: null,
        fill_month: null,
        fill_year: null,
        report_id: null,
        report_type: null,
        report_inner_type: 'reportable',
        period_type: null,
        date_start: null,
        date_end: null,
        number: '',
        year: null,
        payer_name: '',
        payer_ipn_or_passport: '',
        payer_city_name: '',
        payer_city_code: '',
        payer_organization_type: '',
        payer_organization_type_code: '',
        payer_kved_name: '',
        payer_kved_code: '',
        payer_postal_address: '',
        payer_phone: '',
        people_count_main: 0,
        director_ipn: null,
        director_name: null,
        accountant_ipn: null,
        accountant_name: null,
      },
      table_1: {
        row_1000_total_start: 0,
        row_1000_total_end: 0,
        row_1001_start: 0,
        row_1001_end: 0,
        row_1002_start: 0,
        row_1002_end: 0,
        row_1005_start: 0,
        row_1005_end: 0,
        row_1010_total_start: 0,
        row_1010_total_end: 0,
        row_1011_start: 0,
        row_1011_end: 0,
        row_1012_start: 0,
        row_1012_end: 0,
        row_1020_start: 0,
        row_1020_end: 0,
        row_1030_start: 0,
        row_1030_end: 0,
        row_1090_start: 0,
        row_1090_end: 0,
        row_1095_total_start: 0,
        row_1095_total_end: 0,
        row_1100_start: 0,
        row_1100_end: 0,
        row_1103_start: 0,
        row_1103_end: 0,
        row_1110_start: 0,
        row_1110_end: 0,
        row_1125_start: 0,
        row_1125_end: 0,
        row_1135_start: 0,
        row_1135_end: 0,
        row_1136_start: 0,
        row_1136_end: 0,
        row_1155_start: 0,
        row_1155_end: 0,
        row_1160_start: 0,
        row_1160_end: 0,
        row_1165_start: 0,
        row_1165_end: 0,
        row_1170_start: 0,
        row_1170_end: 0,
        row_1190_start: 0,
        row_1190_end: 0,
        row_1195_total_start: 0,
        row_1195_total_end: 0,
        row_1200_total_start: 0,
        row_1200_total_end: 0,
        row_1300_balance_start: 0,
        row_1300_balance_end: 0,
        row_1400_start: 0,
        row_1400_end: 0,
        row_1410_start: 0,
        row_1410_end: 0,
        row_1415_start: 0,
        row_1415_end: 0,
        row_1420_start: 0,
        row_1420_end: 0,
        row_1425_start: 0,
        row_1425_end: 0,
        row_1495_total_start: 0,
        row_1495_total_end: 0,
        row_1595_total_start: 0,
        row_1595_total_end: 0,
        row_1600_start: 0,
        row_1600_end: 0,
        row_1610_start: 0,
        row_1610_end: 0,
        row_1615_start: 0,
        row_1615_end: 0,
        row_1620_start: 0,
        row_1620_end: 0,
        row_1621_start: 0,
        row_1621_end: 0,
        row_1625_start: 0,
        row_1625_end: 0,
        row_1630_start: 0,
        row_1630_end: 0,
        row_1665_start: 0,
        row_1665_end: 0,
        row_1690_start: 0,
        row_1690_end: 0,
        row_1695_total_start: 0,
        row_1695_total_end: 0,
        row_1700_total_start: 0,
        row_1700_total_end: 0,
        row_1900_balance_start: 0,
        row_1900_balance_end: 0,
      },
      table_2: {
        row_2000_start: 0,
        row_2000_end: 0,
        row_2120_start: 0,
        row_2120_end: 0,
        row_2240_start: 0,
        row_2240_end: 0,
        row_2280_total_start: 0,
        row_2280_total_end: 0,
        row_2050_start: 0,
        row_2050_end: 0,
        row_2180_start: 0,
        row_2180_end: 0,
        row_2270_start: 0,
        row_2270_end: 0,
        row_2285_total_start: 0,
        row_2285_total_end: 0,
        row_2290_total_start: 0,
        row_2290_total_end: 0,
        row_2300_start: 0,
        row_2300_end: 0,
        row_2350_total_start: 0,
        row_2350_total_end: 0,
      },
      exclude: [
        'row_1420_end',
        'row_1420_start',
        'row_1495_total_end',
        'row_1495_total_start',
        'row_2290_total_start',
        'row_2300_end',
        'row_2350_total_start'
      ],
      report_dialog: {
        dialog: false,
        component_name: '',
        report_title: '',
        accounting_id: null
      },
    }
  },
  methods: {
    print() {
      window.print()
    },
    openReportDialog(component_name, report_title) {
      if (!component_name) {
        this.report_dialog.dialog = false
        this.report_dialog.component_name = ''
        this.report_dialog.report_title = ''
        this.report_dialog.accounting_id = null
      }
      this.report_dialog.component_name = component_name
      this.report_dialog.report_title = report_title
      this.report_dialog.dialog = true
      this.report_dialog.accounting_id = null
    },
    closeReportDialog() {
      this.report_dialog.dialog = false
      this.report_dialog.component_name = ''
      this.report_dialog.report_title = ''
      this.report_dialog.accounting_id = null
    },
    save_data_after_fetch(data) {
      this.table_1 = data.table_1
      this.table_2 = data.table_2
      this.header = data.header

      this.header.report_id = this.report_id

      const vm = this
      setTimeout(() => {
        vm.formatAllValues()
      },1000)
    },
    report_not_ready(show_errors = false) {
      const errors = {
        header: [],
        table_1: [],
        table_2: [],
      }

      if (!this.header.period_type) {
        errors.header.push('[Налаштування звіту] Не заповнений тип періоду')
      }
      if (!this.header.year) {
        errors.header.push('[Налаштування звіту] Не заповнений рік')
      }
      if (!this.header.number) {
        errors.header.push('[Налаштування звіту] Не заповнений № розрахунку')
      }
      if (!this.header.payer_name) {
        errors.header.push('[Налаштування звіту] Не заповнена назва підприємства')
      }
      if (!this.header.payer_ipn_or_passport) {
        errors.header.push('[Налаштування звіту] Не заповнений код ЄДРПОУ')
      }
      if (!this.header.payer_city_name) {
        errors.header.push('[Налаштування звіту] Не заповнена територія')
      }
      if (!this.header.payer_city_code) {
        errors.header.push('[Налаштування звіту] Не заповнений код за КАТОТТГ')
      }
      if (!this.header.payer_organization_type) {
        errors.header.push('[Налаштування звіту] Не заповнена організаційно-правова форма господарювання')
      }
      if (!this.header.payer_organization_type_code) {
        errors.header.push('[Налаштування звіту] Не заповнений код за КОПФГ')
      }
      if (!this.header.payer_kved_name) {
        errors.header.push('[Налаштування звіту] Не заповнений вид економічної діяльності')
      }
      if (!this.header.payer_kved_code) {
        errors.header.push('[Налаштування звіту] Не заповнений код за КВЕД')
      }
      if (this.header.people_count_main === null || this.header.people_count_main === '') {
        errors.header.push('[Налаштування звіту] Не заповнена середня кількість працівників')
      }
      if (!this.header.payer_postal_address) {
        errors.header.push('[Налаштування звіту] Не заповнена адреса')
      }
      if (!this.header.payer_phone) {
        errors.header.push('[Налаштування звіту] Не заповнений телефон')
      }
      if (!this.header.director_ipn) {
        errors.header.push('[Налаштування звіту] Не заповнений ІПН керівника')
      }
      if (!this.header.director_name) {
        errors.header.push('[Налаштування звіту] Не заповнено ПІБ керівника')
      }

      if (this.table_1.row_1300_balance_start !== this.table_1.row_1900_balance_start) {
        errors.table_1.push(`[Баланс] Не співпадає баланс на початок звітного року. Актив: ${this.table_1.row_1300_balance_end}, пасив: ${this.table_1.row_1900_balance_end}, різниця: ${this.table_1.row_1300_balance_end - this.table_1.row_1900_balance_end}`)
      }
      if (this.table_1.row_1300_balance_start !== this.table_1.row_1900_balance_start) {
        errors.table_1.push(`[Баланс] Не співпадає баланс на кінець звітного року. Актив: ${this.table_1.row_1300_balance_end}, пасив: ${this.table_1.row_1900_balance_end}, різниця: ${this.table_1.row_1300_balance_end - this.table_1.row_1900_balance_end}`)
      }

      const regexp = /(?<table_name>^\[.*?\])?(?<row_number>\(.*?\))?(?<error_text>.*)/
      const error_count = errors.header.length + errors.table_1.length + errors.table_2.length

      this.report_state.errors = []
      if (show_errors && error_count > 0) {
        this.report_state.errors = errors.header.concat(errors.table_1, errors.table_2).map(item => {
          const groups = item.match(regexp).groups
          let {table_name, row_number, error_text} = groups
          table_name = (table_name || '').replace('[', '')
          table_name = table_name.replace(']', '').trim()
          row_number = (row_number || '').replace('(', '')
          row_number = row_number.replace(')', '')
          row_number = row_number.replace('Рядок №', '').trim()
          error_text = error_text.trim()

          return {table_name, row_number, error_text}
        })
        this.report_state.show = true
      }

      return error_count > 0
    },
    formatValue(value) {
      let numeric_value = round_number(value / 1000, 1)
      const string_value = numeric_value.toString()
      const dot_index = string_value.indexOf('.')

      if (dot_index === - 1) {
        if (!numeric_value) {
          return '0.0'
        } else {
          return string_value + '.' + '0'
        }
      } else {
        return string_value.substring(0, dot_index) + '.' + string_value.substring(dot_index + 1, dot_index + 2)
      }
    },
    formatAllValues() {
      Object.keys(this.table_1).forEach(item => {
        const el = this.$refs[item]
        let value = this.table_1[item] || 0

        if (this.exclude.includes(item) && value < 0) {
          value = -value
        }

        if (el) {
          if (el.tagName === 'TD' || el.tagName === 'SPAN') {
            el.textContent = this.formatValue(value)
          } else {
            el.value = this.formatValue(value)
          }
        }
      })

      Object.keys(this.table_2).forEach(item => {
        const el = this.$refs[item]
        let value = this.table_2[item] || 0

        if (this.exclude.includes(item) && value < 0) {
          value = -value
        }

        if (el) {
          if (el.tagName === 'TD' || el.tagName === 'SPAN') {
            el.textContent = this.formatValue(value)
          } else {
            el.value = this.formatValue(value)
          }
        }
      })

    },
    onTabsChange() {
      this.$nextTick(() => {
        this.formatAllValues()
      })
    },
    onValueInput(payload, row) {
      let current_value = payload.target.value
      current_value = current_value.replace(',', '.')
      current_value = current_value.replace('..', '.')
      current_value = current_value.replace(' ', ' ')
      current_value = +(current_value || '0')

      let table = row.split('.')[0]
      let table_row = row.split('.')[1]

      this[table][table_row] = current_value
    },
    onInputFocusIn(payload, row) {
      // let current_value = +(payload.target.value || '0')
      let table = row.split('.')[0]
      let table_row = row.split('.')[1]

      payload.target.value = `${this[table][table_row] || 0}`
    },
    onInputFocusOut(payload, row) {
      let current_value = +(payload.target.value || '0')
      const row_split = row.split('.')
      if (this.exclude.includes(row_split[1]) && current_value < 0) {
        current_value = -current_value
      }
      payload.target.value = this.formatValue(current_value)
    },
    format_date_from_python,
    calcTableTotal_1() {
      this.table_1.row_1000_total_start = this.table_1.row_1001_start - this.table_1.row_1002_start
      this.table_1.row_1010_total_start = this.table_1.row_1011_start - this.table_1.row_1012_start
      this.table_1.row_1095_total_start = this.table_1.row_1000_total_start + this.table_1.row_1005_start
          + this.table_1.row_1010_total_start + this.table_1.row_1020_start + this.table_1.row_1030_start
          + this.table_1.row_1090_start
      this.table_1.row_1095_total_end = this.table_1.row_1000_total_end + this.table_1.row_1005_end
          + this.table_1.row_1010_total_end + this.table_1.row_1020_end + this.table_1.row_1030_end
          + this.table_1.row_1090_end
      this.table_1.row_1195_total_start = this.table_1.row_1100_start + this.table_1.row_1110_start
          + this.table_1.row_1125_start + this.table_1.row_1135_start + this.table_1.row_1155_start
          + this.table_1.row_1160_start + this.table_1.row_1165_start + this.table_1.row_1170_start
          + this.table_1.row_1190_start
      this.table_1.row_1195_total_end = this.table_1.row_1100_end + this.table_1.row_1110_end
          + this.table_1.row_1125_end + this.table_1.row_1135_end + this.table_1.row_1155_end
          + this.table_1.row_1160_end + this.table_1.row_1165_end + this.table_1.row_1170_end
          + this.table_1.row_1190_end
      this.table_1.row_1300_balance_start = this.table_1.row_1195_total_start + this.table_1.row_1095_total_start
          + this.table_1.row_1200_total_start
      this.table_1.row_1300_balance_end = this.table_1.row_1195_total_end + this.table_1.row_1095_total_end
          + this.table_1.row_1200_total_end
      this.table_1.row_1495_total_start = this.table_1.row_1400_start + this.table_1.row_1410_start
          + this.table_1.row_1415_start + this.table_1.row_1420_start + this.table_1.row_1425_start
      this.table_1.row_1495_total_end = this.table_1.row_1400_end + this.table_1.row_1410_end
          + this.table_1.row_1415_end + this.table_1.row_1420_end + this.table_1.row_1425_end
      this.table_1.row_1695_total_start = this.table_1.row_1600_start + this.table_1.row_1610_start
          + this.table_1.row_1615_start + this.table_1.row_1620_start + this.table_1.row_1625_start
          + this.table_1.row_1630_start  + this.table_1.row_1665_start  + this.table_1.row_1690_start
      this.table_1.row_1695_total_end = this.table_1.row_1600_end + this.table_1.row_1610_end
          + this.table_1.row_1615_end + this.table_1.row_1620_end + this.table_1.row_1625_end
          + this.table_1.row_1630_end  + this.table_1.row_1665_end  + this.table_1.row_1690_end
      this.table_1.row_1900_balance_start = this.table_1.row_1495_total_start + this.table_1.row_1595_total_start
          + this.table_1.row_1695_total_start + this.table_1.row_1700_total_start
      this.table_1.row_1900_balance_end = this.table_1.row_1495_total_end + this.table_1.row_1595_total_end
          + this.table_1.row_1695_total_end + this.table_1.row_1700_total_end


    },
    calcTableTotal_2() {
      this.table_2.row_2280_total_start = this.table_2.row_2000_start + this.table_2.row_2120_start
          + this.table_2.row_2240_start
      this.table_2.row_2280_total_end = this.table_2.row_2000_end + this.table_2.row_2120_end
          + this.table_2.row_2240_end
      this.table_2.row_2285_total_start = this.table_2.row_2050_start + this.table_2.row_2180_start
          + this.table_2.row_2270_start
      this.table_2.row_2285_total_end = this.table_2.row_2050_end + this.table_2.row_2180_end
          + this.table_2.row_2270_end
      this.table_2.row_2290_total_start = this.table_2.row_2280_total_start - this.table_2.row_2285_total_start
      this.table_2.row_2290_total_end = this.table_2.row_2280_total_end - this.table_2.row_2285_total_end
      this.table_2.row_2350_total_start = this.table_2.row_2290_total_start - this.table_2.row_2300_start
      this.table_2.row_2350_total_end = this.table_2.row_2290_total_end - this.table_2.row_2300_end
    },
    getTable1Total() {
      this.$nextTick(() => {
        this.calcTableTotal_1()
        const vm = this
        setTimeout(() => {
          vm.formatAllValues()
        },500)
      })
    },
    getTable2Total() {
      this.$nextTick(() => {
        this.calcTableTotal_2()
        const vm = this
        setTimeout(() => {
          vm.formatAllValues()
        },500)
      })
    },
    save_report() {
      const payload = {
        report_type: 'small_balance',
        report_inner_type: this.header.report_inner_type,
        period_type: this.header.period_type,
        date_start: this.header.date_start,
        date_end: this.header.date_end,
        number: this.header.number,
        report_form: 'small_balance_j0901107'
      }

      payload.report_data = {
        header: this.header,
        table_1: this.table_1,
        table_2: this.table_2
      }

      if (this.report_is_new) {
        regulatoryAPI.create_report(payload)
            .then(response => response.data)
            .then(data => {
              this.header.report_id = data.id
              this.$store.commit(ALERT_SHOW, {text: 'Звіт успішно створено', color: 'success'})
              this.$emit('reportSaved', data)
            })
            .catch(err => {
              const error = err.response.data.detail;
              this.$store.commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
            })
      } else {
        payload.id = this.report_id
        regulatoryAPI.update_report(payload)
            .then(response => response.data)
            .then(data => {
              this.header.report_id = data.id
              this.$store.commit(ALERT_SHOW, {text: 'Звіт успішно оновлено', color: 'success'})
              this.$emit('reportSaved', data)
            })
            .catch(err => {
              const error = err.response.data.detail;
              this.$store.commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
            })
      }
    },
    generate_report() {
      const payload = {
        report_type: 'small_balance',
        report_inner_type: this.header.report_inner_type,
        period_type: this.header.period_type,
        date_start: this.header.date_start,
        date_end: this.header.date_end,
        number: this.header.number,
        report_form: 'small_balance_j0901107'
      }

      if (!this.header.period_type) {
        this.$store.commit(ALERT_SHOW, {text: 'Заповніть тип періоду', color: 'error'})
        return
      }

      if (!this.header.year) {
        this.$store.commit(ALERT_SHOW, {text: 'Заповніть рік', color: 'error'})
        return
      }

      regulatoryAPI.generate_report(payload)
          .then(response => response.data)
          .then(data => {
            this.table_1 = data.table_1
            this.table_2 = data.table_2

            if (this.report_is_new && !this.report_id) {
              const current_date = getCurrentDate()
              const current_date_split = current_date.split('-')
              this.header.fill_day = current_date_split[2]
              this.header.fill_month = current_date_split[1]
              if (+current_date_split[1] < 10) {
                this.header.fill_month = `0${current_date_split[1]}`
              }
              if (+current_date_split[2] < 10) {
                this.header.fill_day = `0${current_date_split[2]}`
              }
              this.header.fill_year = current_date_split[0]
            }

            this.$store.dispatch(GET_ORGANIZATION_PRINT_DATA)
                .then(
                    data => {
                      this.print_data = data
                      this.header.payer_name = this.organization.full_name
                      this.header.payer_postal_address = this.organization.address_yur
                      this.header.payer_ipn_or_passport = this.organization.code

                      const director = (data.response_people || []).find(i => i.category === 'director')
                      const chief_accountant = (data.response_people || []).find(i => i.category === "chief_accountant")

                      if (director) {
                        this.header.director_name = director.full_name
                        this.header.director_ipn = director.ipn
                      } else {
                        this.header.director_name = ""
                        this.header.director_ipn = ""
                      }

                      if (chief_accountant) {
                        this.header.accountant_name = chief_accountant.full_name
                        this.header.accountant_ipn = chief_accountant.ipn
                      } else {
                        this.header.accountant_name = ""
                        this.header.accountant_ipn = ""
                      }

                      const report_not_ready = this.report_not_ready(true)

                      if (!report_not_ready) {
                        reportAPI.report_settings("regular_report_small_balance")
                            .then(response => response.data)
                            .then(data => {
                              const server_filters = JSON.parse(data)
                              Object.keys(this.header).forEach(key => {
                                if (server_filters[key] !== undefined) {
                                  this.header[key] = server_filters[key]
                                }
                              })
                            })
                      }

                      this.$nextTick(() => {
                        this.formatAllValues()
                      })

                    }
                )
          })
          .catch(err => {
            const error = err.response.data.detail;
            this.$store.commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
          })
    },
    getRegulatoryReportPeriodType,
    onDateChange() {
      this.$nextTick(() => {
        if (!this.header.period_type) {
          this.header.date_start = null
          this.header.date_end = null
        }
        if (this.header.period_type === 'first_quarter') {
          if (this.header.year) {
            const date_start = `${this.header.year}-01-01`
            const date_end = endOfMonth(addMothsToStringDate(date_start, 2), 'string')
            this.header.date_start = date_start
            this.header.date_end = date_end
          } else {
            this.header.date_start = null
            this.header.date_end = null
          }
        }
        if (this.header.period_type === 'half_year') {
          if (this.header.year) {
            const date_start = `${this.header.year}-01-01`
            const date_end = endOfMonth(addMothsToStringDate(date_start, 5), 'string')
            this.header.date_start = date_start
            this.header.date_end = date_end
          } else {
            this.header.date_start = null
            this.header.date_end = null
          }
        }
        if (this.header.period_type === 'month_9') {
          if (this.header.year) {
            const date_start = `${this.header.year}-01-01`
            const date_end = endOfMonth(addMothsToStringDate(date_start, 8), 'string')
            this.header.date_start = date_start
            this.header.date_end = date_end
          } else {
            this.header.date_start = null
            this.header.date_end = null
          }
        }
        if (this.header.period_type === 'year') {
          if (this.header.year) {
            const date_start = `${this.header.year}-01-01`
            const date_end = endOfMonth(addMothsToStringDate(date_start, 11), 'string')
            this.header.date_start = date_start
            this.header.date_end = date_end
          } else {
            this.header.date_start = null
            this.header.date_end = null
          }
        }
      })
    },
    export_report_to_xml_tax_inspection() {
      const payload = {
        report_type: 'small_balance',
        report_inner_type: this.header.report_inner_type,
        period_type: this.header.period_type,
        date_start: this.header.date_start,
        date_end: this.header.date_end,
        number: this.header.number,
        report_form: 'small_balance_j0901107'
      }

      payload.report_data = {
        header: this.header,
        table_1: this.table_1,
        table_2: this.table_2
      }

      payload.report_data.header.report_id = this.report_id

      const report_not_ready = this.report_not_ready(true)

      if (!report_not_ready) {
        regulatoryAPI.save_report_to_xml(payload)
            .then(response => {
              const url = window.URL.createObjectURL(new Blob([response.data]))
              const link = document.createElement('a');
              const file_name = response.headers['x-blob-file-name'] || 'small_balance_j0901107.xml'
              link.href = url;
              link.setAttribute('download', file_name); //or any other extension
              document.body.appendChild(link);
              link.click();
            })
      }
    },
    export_report_to_xml_statistic() {
      const payload = {
        report_type: 'small_balance',
        report_inner_type: this.header.report_inner_type,
        period_type: this.header.period_type,
        date_start: this.header.date_start,
        date_end: this.header.date_end,
        number: this.header.number,
        report_form: 'small_balance_s0110013'
      }

      payload.report_data = {
        header: this.header,
        table_1: this.table_1,
        table_2: this.table_2
      }

      payload.report_data.header.report_id = this.report_id

      const report_not_ready = this.report_not_ready(true)

      if (!report_not_ready) {
        regulatoryAPI.save_report_to_xml(payload)
            .then(response => {
              const url = window.URL.createObjectURL(new Blob([response.data]))
              const link = document.createElement('a');
              const file_name = response.headers['x-blob-file-name'] || 'small_balance_s0110013.xml'
              link.href = url;
              link.setAttribute('download', file_name); //or any other extension
              document.body.appendChild(link);
              link.click();
            })
      }
    },
    questionForDeleteReport() {
      const payload = {
        text: `Ви дійсно хочете видалити звіт?`,
        accept_button: true,
        id: `delete-${this.report_id}`
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    delete_report() {
      const payload = {
        report_type: 'small_balance',
        report_id: this.report_id
      }
      regulatoryAPI.delete_report(payload)
          .then(response => response.data)
          .then(data => {
            this.$emit('reportDelete', data)
          })
          .catch(err => {
            const error = err.response.data.detail;
            this.$store.commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
          })
    },
    watch_modal_answer() {
      this.modal_answer_watcher = this.$watch(
          'modalAnswer',
          {
            handler(payload) {
              if (payload.id === `delete-${this.report_id}`) {
                if (payload.answer) {
                  this.delete_report()
                }
              }
            }
          }
      )
    }
  },
  created() {
    this.watch_modal_answer()
    if (!this.report_is_new && this.report_id) {
      regulatoryAPI.get_report({report_id: this.report_id, report_type: this.report_type})
          .then(response => response.data)
          .then(data => {
            this.save_data_after_fetch(data.report_data, false)
          })
          .catch(err => {
            const error = err.response.data.detail;
            this.$store.commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
          })
    } else {
      const vm = this
      setTimeout(() => {
        vm.formatAllValues()
      },1000)
    }
  },
  beforeDestroy() {
    this.header = {}
    if (this.modal_answer_watcher) {
      this.modal_answer_watcher()
    }
  },
}
</script>

<style scoped lang="scss">
.border-codes-first {
  border-top: 1px solid #000000;
  border-left: 1px solid #000000;
  border-right: 1px solid #000000;
}
.border-codes-middle {
  border-top: 1px solid #000000;
  border-left: 1px solid #000000;
  border-right: 1px solid #000000;
}
.border-codes-last {
  border-top: 1px solid #000000;
  border-bottom: 1px solid #000000;
  border-left: 1px solid #000000;
  border-right: 1px solid #000000;
}
.regulatory-report-input {
  outline: none;
  line-height: inherit;
  width: 86%;
  border-bottom: 1px solid #4CAF50;
  margin-bottom: 3px;
}

.regulatory-report-header-input {
  outline: none;
  line-height: inherit;
  width: 100%;
  border-bottom: 1px solid #000000;
  margin-bottom: 0;
  height: 100%;
  background-color: rgba(209, 222, 195, 0.88);
}

.regulatory-report-header-code-input {
  outline: none;
  line-height: inherit;
  width: 100%;
  border-bottom: none;
  margin-bottom: 0;
  background-color: rgba(209, 222, 195, 0.88);
  text-align: center;
  height: 100%;
  cursor: text;
}

.regulatory-report-header-code-input.error-input {
  background-color: rgba(199, 78, 78, 0.42);
  border-bottom: none !important;
}

.regulatory-report-header-input.error-input {
  background-color: rgba(199, 78, 78, 0.42);
  border-bottom: 1px solid #000000;
}



.regulatory-report-table {
  width: 1100px;
  font-family: Arial, Calibri, serif;
  padding: 4px 12px;
  float: none !important;
  font-size: 11px;
  border-collapse: collapse;

    tr {
      border: 1px solid grey;

      th {
        border: 1px solid grey;
        padding: 2px 6px;
        font-size: 12px;
        font-weight: bold;
        line-height: 16px;
      }
    }

    .without-border {
      border: none !important;
    }

    tr {
      border: 1px solid grey;

      td {
        border: 1px solid grey;
        padding: 2px 6px;
        font-size: 12px;
        line-height: 16px;

        &:nth-child(1) {
          width: 410px;
          max-width: 410px;
          text-align: left;
        }
        &:nth-child(2) {
          width: 80px;
          max-width: 80px;
          text-align: center;
        }
        &:nth-child(3) {
          width: 130px;
          max-width: 130px;
          text-align: center;
        }
        &:nth-child(4) {
          text-align: center;
        }
      }

      .with-border {
        border: 1px solid grey;
      }
    }

    .total {
      border: none !important;

      td {
        font-weight: bold !important;
        font-size: .9rem;
        border: none !important;
      }
    }
}
</style>